exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-case-studies-chatfood-dinein-js": () => import("./../../../src/pages/case-studies/chatfood-dinein.js" /* webpackChunkName: "component---src-pages-case-studies-chatfood-dinein-js" */),
  "component---src-pages-case-studies-chatfood-onboarding-js": () => import("./../../../src/pages/case-studies/chatfood-onboarding.js" /* webpackChunkName: "component---src-pages-case-studies-chatfood-onboarding-js" */),
  "component---src-pages-case-studies-chatfood-pat-js": () => import("./../../../src/pages/case-studies/chatfood-pat.js" /* webpackChunkName: "component---src-pages-case-studies-chatfood-pat-js" */),
  "component---src-pages-case-studies-enbd-js": () => import("./../../../src/pages/case-studies/enbd.js" /* webpackChunkName: "component---src-pages-case-studies-enbd-js" */),
  "component---src-pages-case-studies-fb-js": () => import("./../../../src/pages/case-studies/fb.js" /* webpackChunkName: "component---src-pages-case-studies-fb-js" */),
  "component---src-pages-case-studies-instashop-js": () => import("./../../../src/pages/case-studies/instashop.js" /* webpackChunkName: "component---src-pages-case-studies-instashop-js" */),
  "component---src-pages-case-studies-jitterbug-js": () => import("./../../../src/pages/case-studies/jitterbug.js" /* webpackChunkName: "component---src-pages-case-studies-jitterbug-js" */),
  "component---src-pages-case-studies-laundry-now-js": () => import("./../../../src/pages/case-studies/laundry-now.js" /* webpackChunkName: "component---src-pages-case-studies-laundry-now-js" */),
  "component---src-pages-case-studies-tripadvisor-js": () => import("./../../../src/pages/case-studies/tripadvisor.js" /* webpackChunkName: "component---src-pages-case-studies-tripadvisor-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */)
}

